export default {
	main: {
		home: {
			icon: 'home',
		},
		orders: {
			icon: 'shopping-cart',
		},
		shop: {
			icon: 'shopping-bag',
			children: {
				products: {
					icon: 'circle',
				},
				customers: {
					icon: 'circle',
				},
				groups: {
					icon: 'circle',
				},
				categories: {
					icon: 'circle',
				},
				stocks: {
					icon: 'circle',
				},
				recipes: {
					icon: 'circle',
				},
				recipeCategories: {
					icon: 'circle',
				},
			},
		},
		marketing: {
			icon: 'percent',
			children: {
				bonusSettings: {
					icon: 'circle',
				},
				bonusHistory: {
					icon: 'circle',
				},
				promocodes: {
					icon: 'circle',
				},
				historyPromocodes: {
					icon: 'circle',
				},
				promotions: {
					icon: 'circle',
				},
				rewards: {
					icon: 'circle',
				},
				jettons: {
					icon: 'circle',
				},
				notifications: {
					icon: 'circle',
				},
			},
		},
		statistics: {
			icon: 'activity',
		},
		communications: {
			icon: 'message-square',
			children: {
				reviews: {
					icon: 'circle',
				},
				questionReviews: {
					icon: 'circle',
				},
				messages: {
					icon: 'circle',
				},
				chats: {
					icon: 'circle',
				},
			},
		},
		settings: {
			icon: 'settings',
			children: {
				settings: {
					icon: 'circle',
				},
				stores: {
					icon: 'circle',
				},
				delivery: {
					icon: 'circle',
				},
				contents: {
					icon: 'circle',
				},
				users: {
					icon: 'circle',
				},
				import: {
					icon: 'circle',
				},
			},
		},
	},

	account: {
		profile: {
			icon: 'user',
		},
		logout: {
			icon: 'power',
			action: 'logout',
		},
	},
}
