import { inject } from 'vue'
import config from '@/config/api'

export default {
	isJson: (str) => {
		try {
			JSON.parse(str)
		} catch (e) {
			return false
		}
		return true
	},

	rand: (precision = 10000000) => {
		return Math.floor(Math.random() * precision)
	},

	time: () => {
		return Date.now()
	},

	date: (value, locale = null, options = null) => {
		if (!locale) {
			locale = 'ru-RU'
		}

		if (!options) {
			options = {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: '2-digit',
				minute: '2-digit',
			}
		}

		const dateObj = new Date(value)

		return dateObj.toLocaleDateString(locale, options)
	},

	img: (path) => {
		if (typeof path !== 'string') {
			return path
		}

		return path.replace(config.imgUrl, '')
	},

	priceRound: (value) => {
		return Number.parseFloat(value).toFixed(2)
	},

	isNumeric: (value) => {
		return !isNaN(parseFloat(value)) && isFinite(value)
	},

	formatNumber: (value) => {
		if (isNaN(parseFloat(value)) || !isFinite(value)) return 0

		return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
	},

	getLocales: () => {
		const $settings = inject('$settings')

		let result = {}

		for (const key in $settings.locale) {
			result[key] = null
		}

		return result
	},

	getSelectItemsFromSettings: (key, firstItem = null) => {
		const locale = inject('$locale')
		const settings = inject('$settings')
		let result = firstItem ? [firstItem] : []

		Object.keys(settings[key]).forEach((val) => {
			result.push({
				val: val,
				title: settings[key][val]['lang'][locale.value],
			})
		})

		return result
	},
}
