import { inject } from 'vue'
import { useApi } from '@/composables/api'
import { useStorage } from '@/composables/storage'
import configLang from '@/config/lang'
import { isArray } from 'lodash'

export function useSettings() {
	const storage = useStorage()
	const settings = getFromStorage()

	function getFromStorage(key = null) {
		if (key) {
			return storage.getJson('settings')[key]
		} else {
			return storage.getJson('settings')
		}
	}

	const get = () => getFromStorage()

	function getItem(settingKey, search = null) {
		const result = getItems(settingKey, search)

		return result[0] ? result[0] : null
	}

	function getItems(settingKey, search = null) {
		let result = []

		for (const itemKey in settings[settingKey]) {
			const item = settings[settingKey][itemKey]
			let found = false

			if (!search) {
				found = true
			} else if (search === itemKey) {
				found = true
			} else if (isArray(search) && search.find((el) => itemKey === el)) {
				found = true
			}

			if (found) {
				item.title = item.lang[configLang.default]
				result.push(item)
			}
		}

		return result
	}

	function getSelectItems(key, firstItem = null) {
		const setting = settings[key]
		const locale = inject('$locale')
		let result = firstItem ? [firstItem] : []

		for (const settingKey in setting) {
			const item = setting[settingKey]

			result.push({
				val: settingKey,
				title: item?.lang ? item.lang[locale.value] : item,
			})
		}

		return result
	}

	function set(data, key = null) {
		if (key) {
			const storageData = getFromStorage()

			storageData[key] = data
			data = storageData
		}

		storage.setJson('settings', data)
	}

	async function getFromApi() {
		const { ok, data } = await useApi(true, 'index', 'settings')

		if (ok.value && data.value) {
			return getApiItems(data.value)
		}

		return null
	}

	function getApiItems(items) {
		let result = {}

		for (let i = 0; i < items.length; i++) {
			const key = items[i].key ?? i
			result[key] = getApiOptions(items[i])
		}

		return result
	}

	function getApiOptions(item) {
		if (item.options) {
			let result = {}

			if (item.options.length === undefined) {
				return item.options
			}

			for (let j = 0; j < item.options.length; j++) {
				const key = item.options[j].key ?? j

				result[key] = item.options[j]

				if (item.options[j]['lang']) {
					result[key]['title'] = item.options[j]['lang'][configLang.default]
				}
			}

			return result
		} else {
			return item
		}
	}

	return {
		settings,
		getFromStorage,
		getFromApi,
		getApiItems,
		getApiOptions,
		get,
		set,
		getItems,
		getItem,
		getSelectItems,
	}
}
