import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useEcho } from '@/composables/echo'
import { useApi } from '@/composables/api'

export const useEchoStore = defineStore('echo', () => {
	const { echoClient } = useEcho()

	const newOrders = ref(0)
	const newMessages = ref(0)
	const event = ref(null)
	const action = ref(null)
	const newOrderData = ref(null)
	const data = ref(null)

	if (echoClient) {
		echoClient.private('admin').listen('.OrderUpdate', (e) => {
			newOrders.value = e.count.new
			event.value = 'OrderUpdate'
			action.value = e.action

			if (e.action == 'created') {
				document.getElementById('sound-file').play()
			}
		})

		echoClient.private('admin').listen('.MessageUpdate', (e) => {
			newMessages.value = e.count.new
			event.value = 'MessageUpdate'
		})

		echoClient.private('admin').listen('.SendChat', (e) => {
			event.value = 'SendChat'
			data.value = e
		})

		useApi(true, 'index', 'orders', { where_model: { value: 'count_new' } }).then(
			(response) => {
				if (response.ok.value) {
					newOrders.value = response.result.value.meta.total
				}
			}
		)

		useApi(true, 'index', 'messages', { where_model: { value: 'count_new' } }).then(
			(response) => {
				if (response.ok.value) {
					newMessages.value = response.result.value.meta.total
				}
			}
		)
	}

	return { newOrders, newOrderData, newMessages, event, action, data }
})
