import { serialize } from 'object-to-formdata'
import { ref, toValue } from 'vue'
import config from '@/config/api'
import { useStorage } from '@/composables/storage'
import { useNotify } from '@/composables/notify'
import { isArray } from 'lodash'

export async function useApi(auth, method, model, params = null, keys = null) {
	const result = ref(null)
	const data = ref(null)
	const status = ref(null)
	const ok = ref(null)
	const errors = ref(null)
	const message = ref(null)

	if (!isArray(keys)) {
		keys = [keys]
	}

	if (!params) {
		params = {}
	}

	const notify = useNotify()

	const token = getToken(auth)

	checkAuth()

	let requestUrl = getUrl()

	const response = await fetch(requestUrl, getOptions())

	status.value = response.status
	ok.value = response.ok

	result.value = await getResponseResult(response)

	if (result.value?.data) {
		data.value = result.value.data
	}

	if (result.value?.errors) {
		errors.value = result.value.errors
	}

	if (result.value?.message) {
		message.value = result.value.message
	}

	if (response.status == 401 || response.status == 403) {
		loginRedirect()
	}

	if (response.status == 429) {
		notify.error('form.error_429')
	}

	async function getResponseResult(response) {
		const contentType = response.headers.get('Content-Type')

		if (contentType.includes('application/json')) {
			return await response.json()
		} else if (contentType.includes('text/')) {
			return await response.text()
		} else if (contentType.includes('application/') || contentType.includes('image/')) {
			return await response.blob()
		} else {
			console.log('Unknown response type:', contentType)
			return null
		}
	}

	function getToken() {
		let token = null

		if (auth) {
			const { get } = useStorage()
			token = get('token')
		}

		return token
	}

	function checkAuth() {
		if (auth && token == null) {
			loginRedirect()
		}
	}

	function loginRedirect() {
		const url = new URL(window.location)
		if (url.pathname !== '/login') {
			window.location.href = '/login'
		}
	}

	function getOptions() {
		let options = {}

		if (method == 'index') {
			options = {
				method: 'GET',
			}
		} else if (method == 'show') {
			options = {
				method: 'GET',
			}
		} else if (method == 'store') {
			options = {
				method: 'POST',
				body: getBody(),
			}
		} else if (method == 'update') {
			options = {
				method: 'POST',
				body: getBody({ _method: 'PUT' }),
			}
		} else if (method == 'destroy') {
			options = {
				method: 'POST',
				body: getBody({ _method: 'DELETE' }),
			}
		} else if (method == 'restore') {
			options = {
				method: 'POST',
				body: getBody({ _method: 'PATCH' }),
			}
		} else {
			options = {
				method: 'POST',
				body: getBody(),
			}
		}

		return Object.assign(options, {
			headers: getHeaders(),
		})
	}

	function getHeaders(items = null, format = null) {
		const headers = new Headers({ Accept: 'application/json' })

		if (format === 'json') {
			headers.append('Content-Type', 'application/json;charset=utf-8')
		}

		if (token !== null) {
			headers.append('Authorization', 'Bearer ' + token)
		}

		if (items) {
			for (const key in items) {
				headers.append(key, items[key])
			}
		}

		return headers
	}

	function getUrl() {
		let pathname = ''

		if (['show', 'update', 'destroy', 'restore'].indexOf(method) > -1) {
			pathname = '/' + keys[0]
		}

		let url = new URL(config.baseURL + model + pathname)

		url = addSearchParams(params, url)

		return url
	}

	function addSearchParams(params, url) {
		const isGetRequest = ['index', 'show'].find((item) => item === method)

		if (isGetRequest && params) {
			const paramsValue = toValue(params)

			for (const key in paramsValue) {
				if (!paramsValue[key]) continue

				let parameterValue = toValue(paramsValue[key])

				if (!parameterValue) continue

				parameterValue = parameterValue.value ?? parameterValue

				if (key === 'filters') {
					for (const filterKey in parameterValue) {
						if (parameterValue[filterKey]) {
							const name = `filter[${filterKey}]`
							const value = parameterValue[filterKey]

							url.searchParams.append(name, value)
						}
					}
				} else {
					url.searchParams.append(key, parameterValue)
				}
			}
		}

		return url
	}

	function getBody(additionalParams = {}, format = null) {
		let result = null
		const resultParams = Object.assign(params, additionalParams)

		if (format == 'json') {
			result = JSON.stringify(resultParams)
		} else {
			result = serialize(resultParams, {
				indices: true,
			})
		}

		return result
	}

	return { result, data, status, ok, errors, message, requestUrl }
}
