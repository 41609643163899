import { ref } from 'vue'
import config from '@/config/lang'

export default {
	install: (app, options) => {
		app.config.globalProperties.$langs = ref({})
		const locale = ref(config.default)
		app.config.globalProperties.$locale = locale.value

		options.forEach((item) => {
			import(`../lang/${locale.value}/${item}.js`).then((module) => {
				app.config.globalProperties.$langs.value[item] = module.default
			})
		})

		const lang = (app.config.globalProperties.$lang = (string) => {
			if (string === undefined) return

			const langs = app.config.globalProperties.$langs
			const items = string.split('.')
			const group = items[0]
			const key = items[1]

			if (langs.value[group] && langs.value[group][key]) {
				return langs.value[group][key]
			} else {
				return string
			}
		})

		const langMessage = (app.config.globalProperties.$langMessage = (
			string,
			placeholders = {}
		) => {
			let result = lang(string)

			const placeholdersArr = Object.entries(placeholders)
			if (placeholdersArr.length > 0) {
				for (const [key, value] of placeholdersArr) {
					result = result.replace('%' + key + '%', value)
				}
			}

			return result
		})

		app.provide('$locale', locale)
		app.provide('$lang', lang)
		app.provide('$langMessage', langMessage)
	},
}
