import { useSettings } from '@/composables/settings'

export default {
	install: (app) => {
		const settings = useSettings()
		const data = settings.getFromStorage()

		if (data) {
			app.provide('$settings', data)
			app.config.globalProperties.$settings = data
		}

		const get = (key = '') => {
			let result = null

			if (data !== null) {
				const params = key.split('.')

				if (params[2]) {
					if (
						data[params[0]] &&
						data[params[0]][params[1]] &&
						data[params[0]][params[1]][params[2]]
					) {
						result = data[params[0]][params[1]][params[2]]
					}
				} else if (params[1]) {
					if (data[params[0]] && data[params[0]][params[1]]) {
						result = data[params[0]][params[1]]
					}
				} else if (data[params[0]]) {
					result = data[params[0]]
				} else {
					result = data
				}
			}

			if (result != null) {
				return result
			}
		}

		app.provide('$setting', get)
		app.config.globalProperties.$setting = get
	},
}
